import type { Ref } from 'vue'
import { onBeforeUnmount, onMounted, ref } from 'vue'

export interface IScrollEvent {
	scrollDirection: Ref<'up' | 'down'>
	scrollPosition: Ref<number>
	disable: Ref<boolean>
}

const disable = ref<boolean>(false)

export function useScrollDirectionEvent(): IScrollEvent {
	const scrollDirection = ref<'up' | 'down'>('down')

	const lastScrollPosition = ref<number>(0)

	function scrollCallback() {
		if (!disable.value) {
			const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop

			if (currentScrollPosition > lastScrollPosition.value) {
				scrollDirection.value = 'up'
			}
			else if (currentScrollPosition < lastScrollPosition.value) {
				scrollDirection.value = 'down'
			}

			lastScrollPosition.value = currentScrollPosition
		}
	}

	onMounted(() => {
		window.addEventListener('scroll', scrollCallback)
	})

	onBeforeUnmount(() => {
		window.removeEventListener('scroll', scrollCallback)
	})

	return { scrollPosition: lastScrollPosition, scrollDirection, disable }
}
