export default class LockableVariable<T> {
	private _value: T
	private _locked: boolean

	constructor(value: T, locked: boolean = false) {
		this._value = value
		this._locked = locked
	}

	public getValue(): T {
		return this._value
	}

	public setValue(value: T): this {
		if (!this._locked) {
			this._value = value
		}
		else {
			console.warn('Cannot set value of locked variable')
		}
		return this
	}

	public lock(): this {
		this._locked = true
		return this
	}

	public unlock(): this {
		this._locked = false
		return this
	}

	public isLocked(): boolean {
		return this._locked
	}

	public isUnlocked(): boolean {
		return !this._locked
	}
}
