export default class UserError extends Error {
	public code: string

	constructor(message: string, code: string) {
		super(message)
		this.code = code
		this.name = 'UserError'
	}

	static duplicateUsername(): DuplicateUsernameError {
		return new DuplicateUsernameError()
	}

	static usernameFormat(): UsernameFormatError {
		return new UsernameFormatError()
	}

	static usernameLength(): UsernameLengthError {
		return new UsernameLengthError()
	}

	static passwordLength(): PasswordLengthError {
		return new PasswordLengthError()
	}

	static duplicateEmail(): DuplicateEmailError {
		return new DuplicateEmailError()
	}

	static emailFormat(): EmailFormatError {
		return new EmailFormatError()
	}

	static emailLength(): EmailLengthError {
		return new EmailLengthError()
	}

	static requiredUsername(): RequiredUsernameError {
		return new RequiredUsernameError()
	}

	static requiredEmail(): RequiredEmailError {
		return new RequiredEmailError()
	}

	static requiredPassword(): RequiredPasswordError {
		return new RequiredPasswordError()
	}

	static badCredentials(): BadCredentialsError {
		return new BadCredentialsError()
	}

	static userNotActive(): UserNotActiveError {
		return new UserNotActiveError()
	}

	static maxDeviceReach(): UserMaxDeviceError {
		return new UserMaxDeviceError()
	}

	static auto(code: string): UserError | null {
		const errorMap: Record<string, () => UserError | null> = {
			bad_length_username: UserError.usernameLength,
			bad_format_username: UserError.usernameFormat,
			bad_length_pwd: UserError.passwordLength,
			duplicate_username: UserError.duplicateUsername,
			duplicate_email: UserError.duplicateEmail,
			bad_format_email: UserError.emailFormat,
			bad_length_email: UserError.emailLength,
			required_username: UserError.requiredUsername,
			required_email: UserError.requiredEmail,
			required_pwd: UserError.requiredPassword,
			user_not_active: UserError.userNotActive,
			user_not_found: UserError.badCredentials,
			incorrect_password: UserError.badCredentials,
			max_device: UserError.maxDeviceReach,
		}

		return errorMap[code]?.() ?? null
	}
}

export class UsernameError extends UserError {
	constructor(message: string, code: string) {
		super(message, code)
	}
}

export class EmailError extends UserError {
	constructor(message: string, code: string) {
		super(message, code)
	}
}

export class PasswordError extends UserError {
	constructor(message: string, code: string) {
		super(message, code)
	}
}

export class LoginError extends UserError {
	constructor(message: string, code: string) {
		super(message, code)
	}
}

export class DuplicateUsernameError extends UsernameError {
	constructor() {
		super('Duplicate username', 'duplicate_username')
	}
}

export class UsernameFormatError extends UsernameError {
	constructor() {
		super('Invalid characters in username', 'bad_format_username')
	}
}

export class UsernameLengthError extends UsernameError {
	constructor() {
		super('Invalid username length (must be 3-12 characters)', 'bad_length_username')
	}
}

export class RequiredUsernameError extends UsernameError {
	constructor() {
		super('Username is required', 'required_username')
	}
}

export class DuplicateEmailError extends EmailError {
	constructor() {
		super('Duplicate email', 'duplicate_email')
	}
}

export class RequiredEmailError extends EmailError {
	constructor() {
		super('Email is required', 'required_email')
	}
}

export class EmailFormatError extends EmailError {
	constructor() {
		super('Invalid email format', 'bad_format_email')
	}
}

export class EmailLengthError extends EmailError {
	constructor() {
		super('Invalid email length (must be < 100 characters)', 'bad_length_email')
	}
}

export class PasswordLengthError extends PasswordError {
	constructor() {
		super('Invalid password length (must be 8-32 characters)', 'bad_length_pwd')
	}
}

export class RequiredPasswordError extends PasswordError {
	constructor() {
		super('Password is required', 'required_pwd')
	}
}

export class BadCredentialsError extends LoginError {
	constructor() {
		super('Username or password is invalid', 'error')
	}
}

export class UserNotActiveError extends LoginError {
	constructor() {
		super('User not active', 'user_not_active')
	}
}

export class UserMaxDeviceError extends LoginError {
	constructor() {
		super('Max device reached', 'max_device')
	}
}
