import { createRouter, createWebHistory } from 'vue-router'
import { storeToRefs } from 'pinia'
import { CLocales } from './constants'
import { useSettingStore } from './stores/settingStore'
import About from '@/components/About.vue'
import AgeChecker from '@/components/AgeChecker.vue'
import Favorites from '@/components/Favorites.vue'
import Gallery from '@/components/Gallery.vue'
import ZoomedImage from '@/components/ZoomedImage.vue'
import Tools from '@/utils/tools'
import { useAuthStore } from '@/stores/authStore'
import PageNotFound from '@/components/404.vue'

const routes = [
	{
		path: '/about',
		name: 'Home',
		component: About,
		meta: {
			title: '@meta.about_title',
			description: '@meta.about_description',
			metaTags: {
				'twitter:card': 'summary_large_image',
				'og:title': 'FapFap.ai',
				'og:description': '@meta.about_description',
				'og:url': import.meta.env.VITE_HOST,
				'og:type': 'website',
				'og:image': `${import.meta.env.VITE_HOST}/banner-og-logo.png`,
				'og:image:width': '1200',
				'og:image:height': '630',
				'og:locale': 'en_US',
				'og:site_name': 'fapfap.ai',
			},
		},
	},
	{
		path: '/:pathMatch(.*)*',
		redirect: {
			name: '404',
		},
	},
	{
		path: '/404',
		name: '404',
		component: PageNotFound,
		meta: {
			title: '@meta.404_title',
			description: '@meta.404_description',
			metaTags: {
				'twitter:card': 'summary_large_image',
				'og:title': 'FapFap.ai',
				'og:description': '@meta.404_description',
				'og:url': import.meta.env.VITE_HOST,
				'og:type': 'website',
				'og:image': `${import.meta.env.VITE_HOST}/banner-og-logo.png`,
				'og:image:width': '1200',
				'og:image:height': '630',
				'og:locale': 'en_US',
				'og:site_name': 'fapfap.ai',
			},
		},
	},
	{
		path: '/:pathMatch(.*)*',
		redirect: {
			name: '404',
		},
	},
	{
		path: '/404',
		name: '404',
		component: PageNotFound,
		meta: {
			title: '@meta.404_title',
			description: '@meta.404_description',
			metaTags: {
				'twitter:card': 'summary_large_image',
				'og:title': 'FapFap.ai',
				'og:description': '@meta.404_description',
				'og:url': import.meta.env.VITE_HOST,
				'og:type': 'website',
				'og:image': `${import.meta.env.VITE_HOST}/banner-og-logo.png`,
				'og:image:width': '1200',
				'og:image:height': '630',
				'og:locale': 'en_US',
				'og:site_name': 'fapfap.ai',
			},
		},
	},
	{
		path: '/home',
		name: 'Age Checker',
		component: AgeChecker,
		meta: {
			title: '@meta.gallery_title',
			description: '@meta.ageverification_description',
			metaTags: {
				'twitter:card': 'summary_large_image',
				'og:title': 'FapFap.ai',
				'og:description': '@meta.ageverification_description',
				'og:url': import.meta.env.VITE_HOST,
				'og:type': 'website',
				'og:image': `${import.meta.env.VITE_HOST}/banner-og-logo.png`,
				'og:image:width': '1200',
				'og:image:height': '630',
				'og:locale': 'en_US',
				'og:site_name': 'fapfap.ai',
			},
		},
	},
	{
		path: '/',
		name: 'Gallery',
		component: Gallery,
		meta: {
			title: '@meta.gallery_title',
			description: '@meta.gallery_description',
			metaTags: {
				'twitter:card': 'summary_large_image',
				'og:title': 'FapFap.ai',
				'og:description': '@meta.gallery_description',
				'og:url': import.meta.env.VITE_HOST,
				'og:type': 'website',
				'og:image': `${import.meta.env.VITE_HOST}/banner-og-logo.png`,
				'og:image:width': '1200',
				'og:image:height': '630',
				'og:locale': 'en_US',
				'og:site_name': 'fapfap.ai',
			},
		},
	},
	{
		path: '/favorites',
		name: 'Favorites',
		component: Favorites,
		meta: {
			requiresAuth: true,
			title: '@meta.favorites_title',
		},
	},
	{
		path: '/image/:uuid',
		name: 'Image',
		component: ZoomedImage,
		props: true,
		meta: {
			title: '@meta.fullscreen_image_title',
			description: '@meta.fullscreen_image_description',
			metaTags: {
				'twitter:card': 'summary_large_image',
				'og:title': 'FapFap.ai - Image',
				'og:description': '@meta.fullscreen_image_description', // Start by @, picked up by i18n
				'og:url': `${import.meta.env.VITE_HOST}/image/::uuid::`,
				'og:type': 'website',
				'og:image': `${import.meta.env.VITE_HOST}/img/::uuid::.png`, // value between ::uuid:: will be replaced by the value of the prop uuid
				'og:image:width': '816',
				'og:image:height': '1176',
				'og:locale': 'en_US',
				'og:site_name': 'fapfap.ai',
			},
		},
	},
]

const router = createRouter({
	history: createWebHistory(),
	scrollBehavior: () => {
		return false
	},

	routes,
})

router.beforeEach((to, from, next) => {
	const viewport = document.querySelector('meta[name="viewport"]')

	if (viewport && 'content' in viewport) {
		viewport.content = 'initial-scale=1.0'
		viewport.content = 'width=device-width'
	}
	createAlternateLinks(to.path)

	const authStore = useAuthStore()

	const settings = useSettingStore()
	const { isBot } = storeToRefs(settings)

	if (isBot.value) {
		return next()
	}

	const legalAge = Tools.getCookie('age-verification') === 'true'

	if (!legalAge && to.name !== 'Age Checker') {
		localStorage.setItem('redirectUrl', to.fullPath)
		next({ name: 'Age Checker' })
	}
	else if (to.meta.requiresAuth && !authStore.isAuth()) {
		next({ name: 'Gallery' })
	}
	else {
		next()
	}
})

function createAlternateLinks(path: string) {
	const oldLinks = document.querySelectorAll('link[rel="alternate"]')
	oldLinks.forEach((link) => {
		document.head.removeChild(link)
	})

	const oldCanonical = document.querySelector('link[rel="canonical"]')
	if (oldCanonical) {
		document.head.removeChild(oldCanonical)
	}

	CLocales.forEach(({ code }) => {
		const link = document.createElement('link')
		link.rel = 'alternate'
		link.hreflang = code
		link.href = `${import.meta.env.VITE_PROTOCOL}://${code}.${import.meta.env.VITE_DOMAIN}${path}`
		document.head.appendChild(link)
	})

	const link = document.createElement('link')
	link.rel = 'alternate'
	link.hreflang = 'x-default'
	link.href = `${import.meta.env.VITE_HOST}${path}`
	document.head.appendChild(link)

	const canonical = document.createElement('link')
	canonical.rel = 'canonical'
	canonical.href = `${import.meta.env.VITE_HOST}${path}`
	document.head.appendChild(canonical)
}

export default router
