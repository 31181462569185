import type { ILocale } from '@/types'

export const CLocales: ILocale[] = [
	{ name: 'Français', code: 'fr' },
	{ name: 'English', code: 'en' },
	{ name: '中文', code: 'zh' },
	{ name: '日本語', code: 'ja' },
	{ name: 'Deutsch', code: 'de' },
	{ name: 'Español', code: 'es' },
	{ name: 'Русский', code: 'ru' },
	{ name: 'Italiano', code: 'it' },
]
