import Tools from './utils/tools'
import ImageError from './errors/ImageError'
import type { IFilter, IFilterPreset, IGroupScene, IImage, IImageGallery, IImageGalleryType, IMembershipExpirationAPI, IMembershipTierAPI, IScene, IUser, IUserDeviceAPI, IUserLoginAPI, IUserMembershipAPI, IUserRegistrationAPI, IUserUpdateAPI } from '@/types'
import UserError, { UserMaxDeviceError } from '@/errors/UserError'

export default class Services {
	public requires_auth: boolean = false

	public requiresAuth(): Services {
		const instance = new Services()
		instance.requires_auth = true
		return instance
	}

	public async getOrders(): Promise<IUserMembershipAPI[]> {
		try {
			const response = await fetch('api/orders/history', { method: 'GET' })

			if (this.requires_auth) {
				await this.onRequiresAuth(response)
			}

			if (!response.ok) {
				throw new Error(`Failed to fetch orders: ${response.status} ${response.statusText}`)
			}

			const data = await response.json()

			if (!Array.isArray(data)) {
				throw new TypeError('Invalid data format received')
			}

			if (data) {
				data.forEach((purchase: IUserMembershipAPI) => {
					purchase.created_at = Tools.convertUTCDateToLocalDate(purchase.created_at)
					purchase.end_at = Tools.convertUTCDateToLocalDate(purchase.end_at)
				})
			}

			return data as IUserMembershipAPI[]
		}
		catch (error) {
			console.error('An error occurred while fetching orders:', error)
			throw error
		}
	}

	public async getMembershipTiers(): Promise<IMembershipTierAPI[]> {
		try {
			const response = await fetch('/api/orders/membership/tiers', { method: 'GET' })

			if (!response.ok) {
				throw new Error(`Failed to fetch membership tiers: ${response.status} ${response.statusText}`)
			}

			const data = await response.json()

			if (!Array.isArray(data)) {
				throw new TypeError('Invalid data format received')
			}

			return data as IMembershipTierAPI[]
		}
		catch (error) {
			console.error('An error occurred while fetching membership tiers:', error)
			throw error
		}
	}

	public async getUserDevices(): Promise<IUserDeviceAPI[]> {
		try {
			const response = await fetch('/api/user/devices', { method: 'GET', credentials: 'include' })

			if (this.requires_auth) {
				await this.onRequiresAuth(response)
			}

			if (!response.ok) {
				throw new Error(`Failed to fetch user devices: ${response.status} ${response.statusText}`)
			}

			const data = await response.json()

			if (!Array.isArray(data)) {
				throw new TypeError('Invalid data format received')
			}

			if (data) {
				data.forEach((userDevice: IUserDeviceAPI) => {
					userDevice.last_seen = Tools.convertUTCDateToLocalDate(userDevice.last_seen)
				})
			}

			return data as IUserDeviceAPI[]
		}
		catch (error) {
			console.error('An error occurred while fetching user devices:', error)
			throw error
		}
	}

	public async deleteUserDevice(deviceId: string): Promise<Response> {
		try {
			const response = await fetch(`/api/user/device/${deviceId}`, { method: 'DELETE', credentials: 'include' })

			if (this.requires_auth) {
				await this.onRequiresAuth(response)
			}

			return response
		}
		catch (error) {
			console.error('An error occurred while deleting user device:', error)
			throw error
		}
	}

	public async addImageToFavorites(uuid: string): Promise<Response> {
		try {
			const response = await fetch(`/api/action/favorite`, {
				method: 'POST',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					uuid,
				}),
			})

			if (this.requires_auth) {
				await this.onRequiresAuth(response)
			}

			return response
		}
		catch (error) {
			console.error('An error occurred while favoriting the image:', error)
			throw error
		}
	}

	public async deleteImageFromFavorites(uuid: string): Promise<Response> {
		try {
			const response = await fetch(`/api/action/favorite`, {
				method: 'DELETE',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					uuid,
				}),
			})

			if (this.requires_auth) {
				await this.onRequiresAuth(response)
			}

			return response
		}
		catch (error: any) {
			console.error('An error occurred while delete favorite image:', error)
			throw error
		}
	}

	public async deleteImagesFromFavorites(uuid: string[]): Promise<Response> {
		try {
			const response = await fetch(`/api/action/favorites`, {
				method: 'DELETE',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					images: uuid.map(val => ({ uuid: val })),
				}),
			})

			if (this.requires_auth) {
				await this.onRequiresAuth(response)
			}

			return response
		}
		catch (error: any) {
			console.error('An error occurred while delete favorite images:', error)
			throw error
		}
	}

	public async reportImage(uuid: string): Promise<Response> {
		try {
			const response = await fetch(`/api/action/report`, {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					uuid,
				}),
			})

			if (this.requires_auth) {
				await this.onRequiresAuth(response)
			}

			return response
		}
		catch (error: any) {
			console.error('An error occurred while reporting image:', error)
			throw error
		}
	}

	public async updateUser(data: IUserUpdateAPI): Promise<Response> {
		try {
			const response = await fetch(`/api/user/update`, {
				method: 'POST',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(data),
			})

			if (this.requires_auth) {
				await this.onRequiresAuth(response)
			}

			return response
		}
		catch (error) {
			console.error('An error occurred while update the user:', error)
			throw error
		}
	}

	public async getUserProfile(): Promise<IUser | null> {
		try {
			const response = await fetch(`/api/user/me`, {
				method: 'GET',
				credentials: 'include',
			})

			if (this.requires_auth) {
				await this.onRequiresAuth(response)
			}

			if (response.ok) {
				return await response.json()
			}

			return null
		}
		catch (error) {
			console.error('An error occurred while getting user profile:', error)
			throw error
		}
	}

	public async login(data: IUserLoginAPI): Promise<Response> {
		try {
			const response = await fetch(`/api/user/login`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(data),
			})

			if (this.requires_auth) {
				await this.onRequiresAuth(response)
			}

			return response
		}
		catch (error) {
			console.error('An error occurred while logging in:', error)
			throw error
		}
	}

	public async logout(): Promise<Response> {
		try {
			const response = await fetch(`/api/user/logout`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
			})

			if (this.requires_auth) {
				await this.onRequiresAuth(response)
			}

			return response
		}
		catch (error) {
			console.error('An error occurred while logging out:', error)
			throw error
		}
	}

	public async resetPassword(login: string): Promise<Response> {
		try {
			const response = await fetch(`/api/user/send_reset_pwd`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					login,
				}),
			})

			if (this.requires_auth) {
				await this.onRequiresAuth(response)
			}

			return response
		}
		catch (error) {
			console.error('An error occurred while resetting password:', error)
			throw error
		}
	}

	public async createUser(data: IUserRegistrationAPI): Promise<Response> {
		try {
			const response = await fetch(`/api/user/create`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(data),
			})

			if (this.requires_auth) {
				await this.onRequiresAuth(response)
			}

			return response
		}
		catch (error) {
			console.error('An error occurred while creating user:', error)
			throw error
		}
	}

	public async getB2Token(): Promise<string> {
		try {
			const response = await fetch(`/api/config/b2_token`, {
				method: 'GET',
			})

			if (this.requires_auth) {
				await this.onRequiresAuth(response)
			}

			return (await response.json()).token
		}
		catch (error) {
			console.error('An error occurred while retrieving back blaze token:', error)
			throw error
		}
	}

	public async getScenesConfig(render: 'hentai' | 'realistic'): Promise<IScene[]> {
		try {
			const response = await fetch(`/api/config/scenes/${render}`, {
				method: 'GET',
			})

			if (this.requires_auth) {
				await this.onRequiresAuth(response)
			}

			return await response.json() as IScene[]
		}
		catch (error) {
			console.error('An error occurred while retrieving scenes config:', error)
			throw error
		}
	}

	public async getFiltersConfig(render: string): Promise<IFilter[]> {
		try {
			const response = await fetch(`/api/config/${render}/filters`, {
				method: 'GET',
			})

			return await response.json() as IFilter[]
		}
		catch (error) {
			console.error('An error occurred while retrieving scenes config:', error)
			throw error
		}
	}

	public async getSceneFiltersConfig(render: string): Promise<IGroupScene[]> {
		try {
			const response = await fetch(`/api/config/scenes/${render}/filters`, {
				method: 'GET',
			})

			return await response.json() as IGroupScene[]
		}
		catch (error) {
			console.error('An error occurred while retrieving scenes config:', error)
			throw error
		}
	}

	public async getGallery(page: number, size: number, headers: Headers | Record<string, any>, type: IImageGalleryType, body: string = '', abortController: AbortController | null = null): Promise<IImageGallery> {
		const params = new URLSearchParams({
			page: page.toString(),
			size: size.toString(),
		})

		try {
			const reqCfg: RequestInit = {
				method: 'POST',
				body,
				headers,
			}
			if (abortController) {
				reqCfg.signal = abortController.signal
			}

			const response = await fetch(`/api/${type}?${params}`, reqCfg)

			if (this.requires_auth) {
				await this.onRequiresAuth(response)
			}

			if (navigator.serviceWorker.controller) {
				navigator.serviceWorker.controller.postMessage({
					type: 'SET_AUTH_TOKEN',
					token: response.headers.get('x-cloudflare-authorization'),
				})
			}

			return await response.json()
		}
		catch (error) {
			console.error('An error occurred while retrieving scenes config:', error)
			throw error
		}
	}

	public async getImageInfo(uuid: string): Promise<IImage | ImageError> {
		try {
			const response = await fetch(`/api/image/${uuid}/info`, { method: 'GET' })

			if (this.requires_auth) {
				await this.onRequiresAuth(response)
			}
			const data = await response.json()

			if (!response.ok) {
				throw ImageError.auto(data.detail.code)
			}
			return data as IImage
		}
		catch (error) {
			if (error instanceof ImageError) {
				throw error
			}
			else {
				throw new ImageError('Unexpected Error', 'An unexpected error occurred.')
			}
		}
	}

	public async setCloudflareToken(): Promise<void> {
		try {
			const response = await fetch(`/api/config/token`, { method: 'GET' })

			if (!response.ok) {
				throw new Error(`Failed to refresh cloudflare token: ${response.status} ${response.statusText}`)
			}

			const { token } = await response.json()

			if (navigator.serviceWorker.controller) {
				navigator.serviceWorker.controller.postMessage({
					type: 'SET_AUTH_TOKEN',
					token,
				})
			}
		}
		catch (error) {
			console.error('An error occurred while retrieving cloudflare token:', error)
			throw error
		}
	}

	public async placePaypalOrder(monthDuration: number): Promise<any> {
		try {
			const response = await fetch('/api/orders', {
				method: 'POST',
				body: JSON.stringify({
					month_duration: monthDuration,
				}),
			})

			if (this.requires_auth) {
				await this.onRequiresAuth(response)
			}

			if (!response.ok) {
				throw new Error(`Failed to place paypal order: ${response.status} ${response.statusText}`)
			}

			const order = await response.json()

			return order
		}
		catch (error) {
			console.error('An error occurred while placing paypal order:', error)
			throw error
		}
	}

	public async completePaypalOrder(orderId: string): Promise<any> {
		try {
			const response = await fetch(`/api/orders/${orderId}/capture`, { method: 'POST' })

			if (this.requires_auth) {
				await this.onRequiresAuth(response)
			}

			if (!response.ok) {
				throw new Error(`Failed to place paypal order: ${response.status} ${response.statusText}`)
			}

			const order = await response.json()

			return order
		}
		catch (error) {
			console.error('An error occurred while completing paypal order:', error)
			throw error
		}
	}

	public async saveFilterSet(filterSet: Omit<IFilterPreset, 'id'>): Promise<IFilterPreset> {
		try {
			const response = await fetch(`/api/preset/filter_set`, {
				method: 'POST',
				body: JSON.stringify(filterSet),
				headers: {
					'Content-Type': 'application/json',
				},
			})

			if (this.requires_auth) {
				await this.onRequiresAuth(response)
			}

			return await response.json()
		}
		catch (error) {
			console.error('An error occurred while creating filter set:', error)
			throw error
		}
	}

	public async getFilterSets(): Promise<IFilterPreset[]> {
		try {
			const response = await fetch(`/api/preset/filter_set`, {
				method: 'GET',
			})

			if (this.requires_auth) {
				await this.onRequiresAuth(response)
			}

			return await response.json()
		}
		catch (error) {
			console.error('An error occurred while retrieving filter sets:', error)
			throw error
		}
	}

	public async updateFilterSet(filterSet: IFilterPreset): Promise<Response> {
		try {
			const response = await fetch(`/api/preset/filter_set`, {
				method: 'PATCH',
				body: JSON.stringify(filterSet),
				headers: {
					'Content-Type': 'application/json',
				},
			})

			if (this.requires_auth) {
				await this.onRequiresAuth(response)
			}

			return response
		}
		catch (error) {
			console.error('An error occurred while updating filter set:', error)
			throw error
		}
	}

	public async deleteFilterSet(id: number): Promise<Response> {
		try {
			const response = await fetch(`/api/preset/filter_set/${id}`, {
				method: 'DELETE',
			})

			if (this.requires_auth) {
				await this.onRequiresAuth(response)
			}

			return response
		}
		catch (error) {
			console.error('An error occurred while deleting filter set:', error)
			throw error
		}
	}

	private async onRequiresAuth(response: Response): Promise<void> {
		if (!response.ok) {
			const clone = response.clone()
			const data = await clone.json()
			const userError = UserError.auto(data.detail.code)
			if (userError instanceof UserMaxDeviceError) {
				Tools.deleteCookie('auth', `.${import.meta.env.VITE_DOMAIN}`, '/')
				window.location.reload()
			}
		}
	}
}
