<script setup lang="ts">
import { defineAsyncComponent, inject, onBeforeUnmount, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { BookHeart, CircleX, ClockArrowDown, Images, Trash2, TriangleAlert, WandSparkles } from 'lucide-vue-next'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { useConfirm } from 'primevue/useconfirm'
import { EUserModalType } from '@/types'
import { useUserModalStore } from '@/stores/userModalStore'
import { useScrollDirectionEvent } from '@/composables/useScrollDirectionEvent'
import { useFavorites } from '@/composables/useFavorites'
import { useFilters } from '@/composables/useFilters'
import { useFavoriteStore } from '@/stores/favoriteStore'
import { useToastStore } from '@/stores/toastStore'
import type Services from '@/services'
import { useFilterStore } from '@/stores/filterStore'

const UserModal = defineAsyncComponent(() => import('@/components/user/UserModal.vue'))

const toastStore = useToastStore()
const { togglePanel } = useFilters()
const { t } = useI18n()
const userModalStore = useUserModalStore()
const favoriteStore = useFavoriteStore()
const filterStore = useFilterStore()
const route = useRoute()
const { routeToFavorites } = useFavorites()

const { scrollDirection } = useScrollDirectionEvent()
const { displayedModal } = storeToRefs(userModalStore)
const { toDelete } = storeToRefs(favoriteStore)
const { selectedFilters } = storeToRefs(filterStore)

const showFilterBtn = ref<boolean>(false)
const showTrashBtn = ref<boolean>(false)

showFilterBtn.value = route.path === '/'
showTrashBtn.value = route.path === '/favorites'

watch(
	() => route.fullPath,
	() => {
		showFilterBtn.value = route.path === '/'
		showTrashBtn.value = route.path === '/favorites'
	},
)

function URLChange() {
	userModalStore.hideUserModal()
	displayedModal.value = EUserModalType.EXIT
}

onBeforeUnmount(() => {
	window.removeEventListener('popstate', URLChange)
})

window.addEventListener('popstate', URLChange)

function clearFilters() {
	filterStore.clearFilters()
	toastStore.add({ severity: 'success', summary: t('notification.filters_reset.title'), detail: t('notification.filters_reset.message'), life: 3000 })
}
</script>

<template>
	<div class="z-[6] flex flex-col items-center justify-center fixed left-1/2 -translate-x-1/2 gap-2" :class="scrollDirection === 'down' ? 'move-up-bottom-menu ' : 'move-down-bottom-menu '">
		<div class="flex flex-nowrap items-end justify-center gap-4">
			<div v-if="showFilterBtn" class="flex flex-col items-center justify-center md:!hidden">
				<Button v-if="selectedFilters.length" class="!bg-gray-800/80 hover:!bg-gray-800 border-none flex-center p-0 px-2 h-[2.5rem] gap-1 mb-2" @click="clearFilters">
					<template #default>
						<CircleX :size="20" />
						<span class="text-sm md:text-base">{{ selectedFilters.length }}</span>
					</template>
				</Button>

				<Button v-if="showFilterBtn" class="!rounded-2xl shadow-md shadow-black/90 w-[3.4rem] h-[3.4rem] sm:w-[4rem] sm:h-[4rem] flex-center" @click="togglePanel">
					<template #default>
						<WandSparkles class="!stroke-orange-100" />
					</template>
				</Button>
			</div>

			<div v-if="showTrashBtn" class="flex flex-col items-center justify-center">
				<Button v-if="toDelete.length" class="!bg-gray-800/80 hover:!bg-gray-800 border-none flex-center p-0 px-2 h-[2.5rem] gap-1 mb-2" @click="toDelete = []">
					<template #default>
						<CircleX :size="20" />
						<span class="text-sm md:text-base">{{ toDelete.length }}</span>
					</template>
				</Button>

				<Button class="!rounded-2xl shadow-md shadow-black/90 w-[3.4rem] h-[3.4rem] sm:w-[4rem] sm:h-[4rem] flex-center" :disabled="!toDelete.length" @click="userModalStore.displayUserModal(EUserModalType.DELETE_FAVORITES)">
					<template #default>
						<Trash2 />
					</template>
				</Button>
			</div>
			<Button v-if="showFilterBtn || showTrashBtn" class="!rounded-2xl shadow-md shadow-black/90 w-[3.4rem] h-[3.4rem] sm:w-[4rem] sm:h-[4rem] flex-center" @click="userModalStore.displayUserModal(EUserModalType.AUTO_SCROLL)">
				<template #default>
					<ClockArrowDown />
				</template>
			</Button>
		</div>
		<div class="flex p-[3px] flex-nowrap w-fit bg-purple-600 gap-0 sm:gap-1 rounded-2xl shadow-md shadow-black/90 [&_svg]:stroke-[#FBD2FB] md:hidden ">
			<router-link to="/" class="button">
				<div v-tooltip.top="t('menu.gallery')" class="bottom-menu-item">
					<Images class="bottom-menu-icon !stroke-purple-100" />
				</div>
			</router-link>
			<router-link to="/favorites" class="button" @click.prevent="routeToFavorites">
				<div v-tooltip.top="t('menu.favorites')" class="bottom-menu-item">
					<BookHeart class="bottom-menu-icon !stroke-purple-100" />
				</div>
			</router-link>
		</div>
	</div>

	<UserModal />
</template>

<style scoped lang="scss">
@media screen and (max-width: 768px) {
	.move-up-bottom-menu {
		bottom: 12px;
		transition: bottom 0.3s ease-in-out;
	}

	.move-down-bottom-menu {
		bottom: -71px;
		transition: bottom 0.3s ease-in-out;
	}
}

@media screen and (min-width: 768px) {
	.move-up-bottom-menu,
	.move-down-bottom-menu  {
		bottom: 30px;
	}
}

@media screen and (max-width: 640px) {
	.move-down-bottom-menu {
		bottom: -64px;
		transition: bottom 0.3s ease-in-out;
	}
}
</style>
