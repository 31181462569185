import type { DirectiveBinding } from 'vue'
import Tools from '@/utils/tools'

declare global {
	interface HTMLElement {
		_observer?: IntersectionObserver
	}
}

const intersectOutsideDirective = {
	mounted(el: HTMLElement, binding: DirectiveBinding) {
		const options = {
			rootMargin: '0px',
			threshold: 0.01,
		}

		const callback: IntersectionObserverCallback = Tools.createDebouncedFunction((entries: IntersectionObserverEntry[]) => {
			entries.forEach((entry) => {
				if (!entry.isIntersecting) {
					binding.value()
				}
			})
		}, 200)

		const observer = new IntersectionObserver(callback, options)
		observer.observe(el)

		el._observer = observer
	},
	unmounted(el: HTMLElement) {
		if ('_observer' in el) {
			el._observer?.disconnect()
		}
	},
}

export default intersectOutsideDirective
