import type { Ref } from 'vue'
import { ref, shallowRef, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useSettingStore } from '@/stores/settingStore'
import { useGalleryStore } from '@/stores/galleryStore'
import { useFilterStore } from '@/stores/filterStore'

export interface IUseFilters {
	open: Ref<boolean>
	buttonsDisabled: Ref<boolean>
	togglePanel: () => void
	refreshGallery: () => Promise<void>
	shuffleGallery: () => void
	shuffleInView: Ref<boolean>
	refreshInView: Ref<boolean>
	filtersButtonsInView: Ref<boolean>
	expandFilters: Ref<boolean>
}

const open = shallowRef<boolean>(false)
const disableButtons = shallowRef<boolean>(false)
const buttonsDisabled = shallowRef<boolean>(false)
const shuffleInView = shallowRef<boolean>(true)
const refreshInView = shallowRef<boolean>(true)
const filtersButtonsInView = shallowRef<boolean>(true)
const expandFilters = shallowRef<boolean>(false)

watch(() => disableButtons.value, () => {
	if (disableButtons.value) {
		disableButtons.value = false
		buttonsDisabled.value = true
		setTimeout(() => buttonsDisabled.value = false, 500)
	}
})

function togglePanel(): void {
	open.value = !open.value
}

export async function refreshGallery(): Promise<void> {
	if (buttonsDisabled.value) {
		return
	}
	const settingStore = useSettingStore()
	const imageStore = useGalleryStore()

	disableButtons.value = true
	settingStore.resetTimestamp()
	settingStore.randomizeSeed()
	imageStore.reset()
	await imageStore.loadMoreImages()
}

export function shuffleGallery(): void {
	if (buttonsDisabled.value) {
		return
	}
	const filterStore = useFilterStore()
	const { shuffle } = storeToRefs(filterStore)

	shuffle.value = !shuffle.value
	disableButtons.value = true
}

export function useFilters(): IUseFilters {
	return { open, buttonsDisabled, shuffleInView, refreshInView, filtersButtonsInView, expandFilters, togglePanel, refreshGallery, shuffleGallery }
}
