<script setup lang="ts">
import { defineAsyncComponent, nextTick, onBeforeUnmount, onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import Toast from 'primevue/toast'
import { onBeforeRouteLeave } from 'vue-router'
import { useFavoriteStore } from '@/stores/favoriteStore'
import { useSettingStore } from '@/stores/settingStore'
import { useAuthStore } from '@/stores/authStore'
import { IImageGalleryType } from '@/types'
import { useAutoScroll } from '@/composables/useAutoScroll'

const AdDisplay = defineAsyncComponent(() => import('@/components/AdDisplay.vue'))
const Image = defineAsyncComponent(() => import('@/components/Image.vue'))

const { stop: stopAutoScroll } = useAutoScroll()

const { t } = useI18n()
const authStore = useAuthStore()
const settingStore = useSettingStore()
const imageStore = useFavoriteStore()

const { galleryType, imageFullScreen, favoriteScroll } = storeToRefs(settingStore)
const { toDelete } = storeToRefs(imageStore)
const { user } = storeToRefs(authStore)

galleryType.value = IImageGalleryType.FAVORITE

function zoomImage(index: number) {
	imageStore.setZoomedImage(index)
	imageFullScreen.value = true
}

onMounted(async () => {
	await nextTick()
	if (!imageStore.allImages.length) {
		await imageStore.loadMoreImages()
	}
	scrollTo({ top: favoriteScroll.value })
})

onBeforeUnmount(() => {
	favoriteScroll.value = window.scrollY
	toDelete.value = []
})

onBeforeRouteLeave(async () => {
	stopAutoScroll()
})
</script>

<template>
	<Toast
		:pt="{
			root: {
				class: 'p-custom-toast',
			},
		}"
	/>
	<div class="mt-[50px] mb-[5px] w-full flex justify-center">
		<div class="ad-container hidden ad:block">
			<AdDisplay exo-zone-id="5211280" exo-class="eas6a97888e2" class="ad-container-left" />
		</div>

		<div v-if="imageStore.allImages.length" class="w-[100%] flex flex-wrap justify-center relative gap-1">
			<template v-for="(image, index) in imageStore.allImages" :key="image.uuid">
				<AdDisplay
					v-if="index && index % 24 === 0"
					exo-zone-id="5295868" exo-class="eas6a97888e20" class="
				flex justify-center
				sm:w-[250px] sm:h-[360px] lg:w-[315px] lg:h-[454px] xl:w-[420px] xl:h-[606px]
				[&_.exo-native-widget]:!w-full
				[&_.exo-native-widget]:!h-auto
				sm:[&_.exo-native-widget]:!w-[205px]
				xl:[&_.exo-native-widget]:!w-[410px]
				lg:[&_.exo-native-widget]:!w-[305px]
				[&_.exo-native-widget]:!bg-transparent"
				/>
				<div class="flex justify-center w-full h-auto sm:w-[250px] sm:h-[360px] lg:w-[315px] lg:h-[454px] xl:w-[420px] xl:h-[606px]">
					<Image :data="imageStore.allImages[index]" :index="image.uuid" @on-image-zoom="zoomImage(index)" />
				</div>
			</template>
			<div v-intersect="imageStore.loadMoreImages" class="w-full h-[10px] absolute bottom-[250px] -z-[1]" />
		</div>
		<div v-else class="flex flex-col">
			<div class="px-4 rounded-md flex flex-col">
				<div class="flex items-center gap-2 sm:gap-5">
					<div class="flex flex-col">
						<h4 class="text-h5 sm:text-h4">
							<span class="text-purple-400">></span> {{ t('favorites.empty.title') }}
						</h4>
						<p class="m-0 pl-1 font-semibold text-sm md:text-base">
							{{ t('favorites.empty.paragraph1') }}
						</p>

						<p class="m-0 pl-1 mt-5 font-semibold text-sm md:text-base">
							{{ t('favorites.empty.paragraph2') }}
						</p>
						<p
							class="m-0 mt-8 sm:mt-6 pl-1 font-medium h-[18px] flex items-center gap-1 text-sm md:text-base"
						>
							{{ t('favorites.empty.paragraph3') }}
						</p>
					</div>
				</div>
			</div>
			<div class="flex justify-center items-end w-full">
				<router-link to="/" class="w-11/12 sm:w-6/12 mt-[3rem]">
					<Button outlined :label="t('favorites.empty.button')" class="w-full" />
				</router-link>
			</div>
		</div>

		<div class="ad-container hidden ad:block">
			<AdDisplay exo-zone-id="5211280" exo-class="eas6a97888e2" class="ad-container-right" />
		</div>
	</div>
</template>

<style scoped lang="scss">
.ad-container {
    position: relative;
    width: 230px;
    height: 100%;
}

@media screen and ((max-width: 800px) or (max-height: 850px)) {
    .ad-container {
        display: none;
    }
}
</style>
