<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>

<template>
	<div class="flex mt-[50px] w-screen h-[calc(100vh-50px)] items-center justify-center">
		<div class="h-[30rem] w-[30rem] flex justify-center items-center flex-col">
			<h1 class="text-purple-500 text-9xl m-0 p-0 font-extrabold">
				404
			</h1>
			<p class="p-0 m-0 font-medium">
				{{ t('404.message') }}
			</p>
			<router-link to="/">
				<Button :label="t('404.button')" class="mt-4" />
			</router-link>
		</div>
	</div>
</template>

<style scoped lang="scss">

</style>
