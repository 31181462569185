export default class ImageError extends Error {
	public code: string

	constructor(message: string, code: string) {
		super(message)
		this.code = code
		this.name = 'ImageError'
	}

	static duplicateFavorite(): DuplicateFavoriteError {
		return new DuplicateFavoriteError()
	}

	static favoritesLimitExceeded(): FavoritesLimitExceededError {
		return new FavoritesLimitExceededError()
	}

	static imageNotFound(): ImageNotFound {
		return new ImageNotFound()
	}

	static unauthorizedResource(): UnauthorizedResource {
		return new UnauthorizedResource()
	}

	static auto(code: string): ImageError | null {
		const errorMap: Record<string, () => ImageError | null> = {
			duplicate_favorite: ImageError.duplicateFavorite,
			image_not_found: ImageError.imageNotFound,
			unauthorized_resource: ImageError.unauthorizedResource,
			favorites_limit_exceeded: ImageError.favoritesLimitExceeded,
		}

		return errorMap[code]?.() ?? null
	}
}

export class DuplicateFavoriteError extends ImageError {
	constructor() {
		super('Duplicate favorite', 'duplicate_favorite')
	}
}

export class FavoritesLimitExceededError extends ImageError {
	constructor() {
		super('Favorites limit exceeded', 'favorites_limit_exceeded')
	}
}

export class ImageNotFound extends ImageError {
	constructor() {
		super('Image not found', 'image_not_found')
	}
}

export class UnauthorizedResource extends ImageError {
	constructor() {
		super('Access denied: User lacks the necessary authorization to access this resource.', 'unauthorized_resource')
	}
}
