import type { ILocale } from '.'

export enum EHandedness {
	LEFT = 0,
	RIGHT = 1,
}

export interface ISettingStore {
	locale: ILocale
	handedness: EHandedness
}
