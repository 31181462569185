import { defineStore, storeToRefs } from 'pinia'
import { computed, inject, nextTick, ref, watch } from 'vue'
import { useSettingStore } from './settingStore'
import { useFilterStore } from './filterStore'
import { type IImage, IImageGalleryType } from '@/types'
import type Services from '@/services'
import Tools from '@/utils/tools'

export const useGalleryStore = defineStore('gallery images', () => {
	const services = inject<Services>('services')

	const settingStore = useSettingStore()
	const filterStore = useFilterStore()

	const { seed, timestamp } = storeToRefs(settingStore)
	const { computedFilters, standardFilters } = storeToRefs(filterStore)

	const images = ref<Array<IImage>>([])

	const index = ref<number>(-1)
	let page = 0
	const size = 12

	let abortController = new AbortController()

	function createAlt(image: IImage) {
		if (!image.alt) {
			const alt = []
			for (const k in image.filters) {
				const filterId = Number(k)
				const optionId = image.filters[filterId]
				const filter = standardFilters.value.find(({ id }) => id === filterId)
				if (filter) {
					const optionName = filter.options.find(({ id }) => id === optionId)?.lib
					const filterName = filter.lib
					if (optionName && optionName.length) {
						alt.push(`${filterName} ${optionName}`)
					}
				}
			}
			image.alt = alt.join(', ')
		}
	}

	const loadMoreImages = async (): Promise<void> => {
		const headers = new Headers()
		headers.append('Seed', seed.value.toString())
		headers.append('Timestamp', timestamp.value)
		headers.append('Content-Type', 'application/json')

		abortController?.abort()
		abortController = new AbortController()

		const fetchedImages = await services?.getGallery(++page, size, headers, IImageGalleryType.GALLERY, JSON.stringify(computedFilters.value), abortController)

		if (fetchedImages) {
			fetchedImages.items.forEach(image => createAlt(image))
			images.value = [
				...images.value,
				...fetchedImages.items,
			]
		}
		else {
			console.error('Failed to fetch images')
		}
	}

	const reset = () => {
		page = 0
		index.value = -1
		images.value = []
	}

	async function refreshImages(): Promise<void> {
		reset()
		loadMoreImages()
	}

	(async () => {
		await nextTick()
		watch(() => computedFilters.value, () => {
			Tools.applyDebounce(refreshImages, 500)
		})
	})()

	const setZoomedImage = async (_index: number) => {
		if (_index < 0) {
			throw new Error('Index .')
		}
		else {
			index.value = _index
		}
	}

	const allImages = computed(() => {
		return images.value
	})

	const updateImage = (uuid: string, content: Partial<IImage>): void => {
		const i = images.value.findIndex(image => image.uuid === uuid)

		if (i >= 0) {
			images.value[i] = {
				...images.value[i],
				...content,
			}
		}
	}

	const getImage = (uuid: string): IImage | null => {
		const i = images.value.findIndex(image => image.uuid === uuid)

		return i >= 0 ? images.value[i] : null
	}

	return {
		images,
		page,
		size,
		allImages,
		index,
		loadMoreImages,
		setZoomedImage,
		reset,
		updateImage,
		getImage,
	}
})
