import { defineStore } from 'pinia'
import { computed, inject, ref } from 'vue'
import { addDays } from 'date-fns'
import { useSettingStore } from './settingStore'
import { useGalleryStore } from './galleryStore'
import { useToastStore } from '@/stores/toastStore'
import { i18n } from '@/i18n'
import Tools from '@/utils/tools'
import type { IImage, IUser, IUserLoginAPI, IUserRegistrationAPI, IUserUpdateAPI } from '@/types'
import UserError from '@/errors/UserError'
import type Services from '@/services'

export const useAuthStore = defineStore('authStore', () => {
	const services = inject<Services>('services')

	const toastStore = useToastStore()
	const settingStore = useSettingStore()
	const galleryStore = useGalleryStore()

	const { t } = i18n.global

	const auth = ref<boolean>(false)
	const user = ref<IUser>({
		username: '',
		email: '',
		birthyear: '',
		gender: '',
		premium: {
			active: false,
			expiration_date: '',
		},
		keep_updated: false,
		lang: 'en',
	})

	const logoutCallbacks = ref<Array<() => void>>([])

	const addLogoutCallback = (cb: () => void): void => {
		logoutCallbacks.value.push(cb)
	}

	const authCookie = Tools.getCookie('auth')
	auth.value = authCookie === 'true'

	const refreshUser = () => {
		if (auth.value) {
			services?.getUserProfile()
				.then((_user: IUser | null) => {
					if (_user) {
						user.value = _user
						user.value.premium.active = true
					}
					else {
						console.error(_user)
					}
				})
				.catch((error) => {
					console.error(error)
				})
		}
	}

	refreshUser()

	const updateUser = async (data: IUserUpdateAPI): Promise<void> => {
		data.user.lang = settingStore.getLocale().code

		const res = await services?.requiresAuth().updateUser(data)
		if (res && res.ok) {
			toastStore.add({
				severity: 'success',
				summary: t('notification.dashboard.title'),
				detail: t('notification.dashboard.message'),
				life: 4000,
			})
			if (user.value) {
				if (typeof data.user.username === 'string' && data.user.username.length) {
					user.value.username = data.user.username.length ? data.user.username : user.value.username
				}
				if (typeof data.user.birthyear === 'string') {
					user.value.birthyear = data.user.birthyear
				}
				if (typeof data.user.keep_updated === 'boolean') {
					user.value.keep_updated = data.user.keep_updated
				}
				if (typeof data.user.gender === 'string' && data.user.gender.length) {
					user.value.gender = data.user.gender
				}
				if (typeof data.user.lang === 'string' && data.user.lang.length) {
					user.value.lang = data.user.lang
				}
			}
		}
		else if (res) {
			const error = await res.json()
			throw UserError.auto(error.detail.code)
		}
	}

	const register = async (data: IUserRegistrationAPI): Promise<void> => {
		const res = await services?.createUser(data)
		if (res && !res.ok) {
			const error = await res.json()
			throw UserError.auto(error.detail.code)
		}
	}

	const login = async (data: IUserLoginAPI): Promise<void> => {
		const expirationDate = addDays(new Date(), data.keep_connected ? 31 : 6)

		const res = await services?.login(data)

		if (res && res.ok) {
			const _user = await services?.requiresAuth().getUserProfile()
			if (_user) {
				user.value = _user
				toastStore.add({
					severity: 'success',
					life: 4000,
					summary: t('notification.login.title'),
					detail: t('notification.login.message'),
				})
				auth.value = true
				Tools.setCookie('auth', 'true', expirationDate, `.${import.meta.env.VITE_DOMAIN}`, '/')
				galleryStore.reset()
				await galleryStore.loadMoreImages()
			}
		}
		else if (res) {
			const error = await res.json()
			throw UserError.auto(error.detail.code)
		}
	}

	const logout = async (): Promise<boolean> => {
		const res = await services?.requiresAuth().logout()

		if (res && res.ok) {
			toastStore.add({
				severity: 'success',
				life: 4000,
				summary: t('notification.logout.title'),
				detail: t('notification.logout.message'),
			})
			auth.value = false
			user.value.premium.active = false
			galleryStore.images.forEach((image: IImage) => image.is_favorite = false)
			logoutCallbacks.value.forEach(cb => cb())
			return true
		}
		else {
			return false
		}
	}

	const isAuth = (): boolean => {
		const authCookie = Tools.getCookie('auth')
		auth.value = authCookie === 'true'
		return auth.value
	}

	return { isAuth, user, auth, login, logout, register, updateUser, refreshUser, addLogoutCallback }
})
