import { defineStore } from 'pinia'
import { ref } from 'vue'
import { EUserModalType } from '@/types'

export const useUserModalStore = defineStore('userModalStore', () => {
	const displayedModal = ref<EUserModalType>(EUserModalType.LOGIN)
	const visibility = ref<boolean>(false)
	const modalProps = ref<Record<string, any>>({})

	function displayUserModal(type: EUserModalType, props: Record<string, any> = {}) {
		visibility.value = true
		displayedModal.value = type
		modalProps.value = props
	}

	function hideUserModal(): void {
		modalProps.value = {}
		visibility.value = false
	}

	return { displayedModal, visibility, modalProps, displayUserModal, hideUserModal }
})
