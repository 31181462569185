import type { ComputedRef, Ref } from 'vue'
import { computed, onMounted, onUnmounted, ref } from 'vue'

export interface IResizeListener {
	screenWidth: Ref<number>
	computedScreenWidth: ComputedRef<number>
}

export function useResizeListener(): IResizeListener {
	const screenWidth = ref(window.innerWidth)

	const computedScreenWidth = computed(() => screenWidth.value)

	const updateWidth = () => {
		screenWidth.value = window.innerWidth
	}

	onMounted(() => {
		window.addEventListener('resize', updateWidth)
	})

	onUnmounted(() => {
		window.removeEventListener('resize', updateWidth)
	})

	return { screenWidth, computedScreenWidth }
}
