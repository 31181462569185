import UAParser from 'ua-parser-js'

export default class DeviceFingerprint {
	private static getGPUInfo(): string {
		const canvas = document.createElement('canvas')
		const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl')
		const gpuInfo = [
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
		]

		if (gl instanceof WebGLRenderingContext) {
			gpuInfo[0] = gl.getParameter(gl.RENDERER)
			gpuInfo[1] = gl.getParameter(gl.VENDOR)
			gpuInfo[2] = gl.getParameter(gl.SHADING_LANGUAGE_VERSION)
			gpuInfo[3] = gl.getParameter(gl.VERSION)
			gpuInfo[4] = gl.getParameter(gl.MAX_TEXTURE_SIZE)
			gpuInfo[5] = gl.getParameter(gl.MAX_COMBINED_TEXTURE_IMAGE_UNITS)
			gpuInfo[6] = gl.getParameter(gl.MAX_VERTEX_TEXTURE_IMAGE_UNITS)
			gpuInfo[7] = gl.getParameter(gl.MAX_FRAGMENT_UNIFORM_VECTORS)
			gpuInfo[8] = gl.getParameter(gl.MAX_VERTEX_UNIFORM_VECTORS)
		}

		return gpuInfo.join('-')
	}

	public static async create(): Promise<string> {
		const userAgent: string = navigator.userAgent
		const uaParser = new UAParser(userAgent)
		const uaParsed = uaParser.getResult()
		const slimUserAgent = `${uaParsed.browser.name}-${uaParsed.cpu.architecture}-${uaParsed.engine.name}-${uaParsed.os.name}`
		const pixelDepth = screen.pixelDepth
		const colorDepth = screen.colorDepth
		const timezoneOffset = new Date().getTimezoneOffset()
		const language: string = navigator.language
		const gpuInfo = DeviceFingerprint.getGPUInfo()
		const cpuThreads: number = navigator.hardwareConcurrency
		const encoded = new TextEncoder().encode(`${slimUserAgent}-${gpuInfo}-${language}-${cpuThreads}-${pixelDepth}-${colorDepth}-${timezoneOffset}`)
		const hashBuffer = await crypto.subtle.digest('SHA-256', encoded)
		return Array.from(new Uint8Array(hashBuffer))
			.map(byte => byte.toString(16).padStart(2, '0'))
			.join('')
	}
}
