import type { Ref } from 'vue'
import { shallowRef, watch } from 'vue'

export interface IUseAutoScroll {
	start: Ref<boolean>
	speed: Ref<number>
	stop: () => void
	setTarget: (target: HTMLElement | Window & typeof globalThis) => void
}

const start = shallowRef<boolean>(false)
const speed = shallowRef<number>(0.7)
let target: HTMLElement | Window & typeof globalThis = window
let animationFrameId: number = 0
let scrollAccumulator: number = 0

function run() {
	scrollAccumulator += speed.value
	if (scrollAccumulator >= 1) {
		target.scrollBy(0, 1)
		scrollAccumulator -= 1
	}
	if (start.value) {
		animationFrameId = requestAnimationFrame(run)
	}
}

watch(() => start.value, () => {
	if (start.value) {
		run()
	}
	else {
		scrollAccumulator = 0
		animationFrameId = 0
		cancelAnimationFrame(animationFrameId)
	}
})

function stop() {
	target = window
	start.value = false
}

function setTarget(_target: HTMLElement | Window & typeof globalThis = window) {
	target = _target
}

export function useAutoScroll(): IUseAutoScroll {
	return { start, speed, stop, setTarget }
}
