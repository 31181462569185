<script setup lang="ts">
import { defineAsyncComponent, ref, watch, watchEffect } from 'vue'
import { BookHeart, Check, Images, Info, LogIn, LogOut, ShoppingBag, User, UserCog, UserPlus, WandSparkles } from 'lucide-vue-next'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { addYears } from 'date-fns'
import { useSettingStore } from '@/stores/settingStore'
import { EUserModalType, type ILocaleCode } from '@/types'
import Tools from '@/utils/tools'
import { useAuthStore } from '@/stores/authStore'
import router from '@/router'
import { useScrollDirectionEvent } from '@/composables/useScrollDirectionEvent'
import { useUserModalStore } from '@/stores/userModalStore'
import { useFavorites } from '@/composables/useFavorites'
import { useFilters } from '@/composables/useFilters'

const LocaleSetter = defineAsyncComponent(() => import('@/components/LocaleSetter.vue'))

const { t } = useI18n()
const settingsStore = useSettingStore()
const authStore = useAuthStore()
const userModalStore = useUserModalStore()
const { routeToFavorites } = useFavorites()
const { togglePanel } = useFilters()

const { user } = storeToRefs(authStore)
const { displayedModal } = storeToRefs(userModalStore)

const locale = ref<ILocaleCode>(settingsStore.getLocale().code)
const { scrollDirection, scrollPosition } = useScrollDirectionEvent()

const route = useRoute()
const showSettingMenu = ref<boolean>(false)
const showFilterBtn = ref<boolean>(false)

showFilterBtn.value = route.path === '/'
watch(() => locale.value, async (code: ILocaleCode) => {
	if (authStore.isAuth()) {
		await authStore.updateUser({ user: { lang: code } })
	}
	Tools.setCookie('lang', code, addYears(new Date(), 50), `.${import.meta.env.VITE_DOMAIN}`, '/')
	window.location.replace(`${import.meta.env.VITE_PROTOCOL}://${code}.${import.meta.env.VITE_DOMAIN}${router.currentRoute.value.path}`)
})

watchEffect(() => {
	if (scrollDirection.value) {
		showSettingMenu.value = false
	}
})

watch(route, () => {
	showFilterBtn.value = route.path === '/'
	showSettingMenu.value = false
})

const discordLink = ref<string>(import.meta.env.VITE_DISCORD_INVITE_LINK ?? '')

async function logout() {
	await authStore.logout()
	userModalStore.hideUserModal()
	displayedModal.value = EUserModalType.EXIT
	showSettingMenu.value = false
	router.push({
		path: '/',
		query: route.query,
	})
}

function openProfile() {
	userModalStore.displayUserModal(EUserModalType.DASHBOARD)
	showSettingMenu.value = false
}

function signIn() {
	userModalStore.displayUserModal(EUserModalType.LOGIN)
	showSettingMenu.value = false
}

function signUp() {
	userModalStore.displayUserModal(EUserModalType.REGISTER)
	showSettingMenu.value = false
}

function openPurchases() {
	userModalStore.displayUserModal(EUserModalType.PURCHASE_HISTORY)
	showSettingMenu.value = false
}
</script>

<template>
	<div class="select-none fixed w-screen bg-gray-900 py-2 px-3 flex items-center justify-between z-[8] h-[50px]" :class="scrollDirection === 'up' && scrollPosition > 400 ? 'move-up-menu ' : 'move-down-menu '">
		<div>
			<h1 class="text-lg xs:text-xl sm:text-2xl font-extrabold select-none m-0">
				FapFap<span class="text-orange-500 rounded-md">.ai</span>
			</h1>
		</div>

		<div class="items-center flex">
			<div class="items-center text-sm md:text-base md:flex md:absolute md:left-1/2 md:transform md:-translate-x-1/2 md:top-1/2 md:-translate-y-1/2 hidden">
				<Button class="rounded-md h-[36px] gap-1 items-center" :disabled="!showFilterBtn" @click="togglePanel">
					<template #default>
						<WandSparkles :size="18" />
						<span>{{ t('filters.title') }}</span>
					</template>
				</Button>
				<Divider layout="vertical" class="" />
				<router-link to="/" class="no-underline">
					<span class="top-menu-item w-full flex items-center gap-2 px-3 py-[6px] hover:bg-purple-600 rounded-md cursor-pointer transform-color duration-300 text-white">
						<Images :size="16" />
						<span>{{ t('menu.gallery') }}</span>
					</span>
				</router-link>
				<router-link to="/favorites" class="no-underline ml-1" @click.prevent="routeToFavorites">
					<span class="top-menu-item w-full flex items-center gap-2 px-3 py-[6px] hover:bg-purple-600 rounded-md cursor-pointer transform-color duration-300 text-white">
						<BookHeart :size="16" />
						<span>{{ t('menu.favorites') }}</span>
					</span>
				</router-link>
			</div>
			<div class="flex gap-3 items-center">
				<div class="relative w-fit">
					<div class="text-sm md:text-base cursor-pointer px-2 h-[2.1rem] flex justify-center items-center gap-1 border-[1px] rounded-full border-orange-400 border-solid hover:bg-gray-700 hover:border-orange-500 transform duration-300" @click="showSettingMenu = !showSettingMenu">
						<User :size="20" />
					</div>
					<div v-show="showSettingMenu" class="absolute top-[41px] -right-[13px] w-fit whitespace-nowrap bg-gray-900 p-2 rounded-bl-md border-0 border-b-2 border-solid border-gray-700">
						<ul class="list-none m-0 p-0 [&>li]:rounded-md [&>li]:cursor-pointer [&>li]:p-1 [&>li]:m-0 hover:[&_li]:bg-purple-600 text-sm md:text-base w-full flex flex-col gap-2">
							<li v-if="!authStore.isAuth()" class="flex items-center gap-2" @click="signIn">
								<LogIn :size="18" />
								<span>{{ t('menu.login') }}</span>
							</li>
							<li v-if="!authStore.isAuth()" class="flex items-center gap-2" @click="signUp">
								<UserPlus :size="18" />
								<span>{{ t('register.title') }}</span>
							</li>
							<li v-if="authStore.isAuth()" class="flex items-center gap-2" @click="openProfile">
								<UserCog :size="18" />
								<span>{{ t('menu.profile') }}</span>
							</li>
							<li v-if="authStore.isAuth()" class="flex items-center gap-2" @click="logout">
								<LogOut :size="18" />
								<span>{{ t('menu.logout') }}</span>
							</li>
							<Divider class="mt-0 mb-0" />
							<li class="!p-0" @click="showSettingMenu = false">
								<a :href="discordLink" target="_SEJ" class="p-1 items-center gap-2 text-white flex no-underline hover:text-gray-200" aria-label="Discord fapfap.ai community">
									<i class="pi pi-discord" />
									<span>Discord</span>
								</a>
							</li>
							<li class="!p-0" @click="showSettingMenu = false">
								<a href="https://www.reddit.com/r/FapFapAI/" target="_SEJ" class="p-1 items-center gap-2 text-white flex no-underline hover:text-gray-200" aria-label="Reddit fapfap.ai community">
									<i class="pi pi-reddit" />
									<span>Reddit</span>
								</a>
							</li>
							<li class="!p-0" @click="showSettingMenu = false">
								<a href="https://x.com/FapFap_ai" target="_SEJ" class="p-1 items-center gap-2 text-white flex no-underline hover:text-gray-200" aria-label="FapFap.ai official twitter account">
									<i class="pi pi-twitter" />
									<span>X</span>
								</a>
							</li>
							<Divider class="mt-0 mb-0" />
							<li class="!p-0" @click="showSettingMenu = false">
								<router-link to="/about" class="p-1 items-center gap-2 text-white flex no-underline hover:text-gray-200">
									<Info :size="16" />
									<span>{{ t('menu.about') }}</span>
								</router-link>
							</li>
							<Divider class="mt-0 mb-0" />
							<li>
								<LocaleSetter v-model="locale" class="w-[110px] h-[30px] !border-none flex items-center" />
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
.logo-title {
	font-family: 'Nova Square', sans-serif;
}

.move-up-menu {
	top: -50px;
	transition: top 0.3s ease-in-out;
}

.move-down-menu {
	top: 0px;
	transition: top 0.3s ease-in-out;
}
</style>
