export interface IImage {
	id: number
	seed: string
	uuid: string
	scene_id: number
	is_favorite: boolean
	premium: boolean
	filters: Record<string, number>
	alt?: string
}

export interface IImageGallery {
	/**
	 * Current page
	 */
	page: number
	/**
	 * Max available pages (relative to the size, eg: total 100, size 2, pages 50)
	 */
	pages: number
	/**
	 * Number of returned image items
	 */
	size: number
	/**
	 * Max available pages (relative to the total of images)
	 */
	total: number
	/**
	 * Images of the current page
	 */
	items: IImage[]
}

export enum IImageGalleryType {
	FAVORITE = 'gallery/favorite',
	GALLERY = 'gallery',
}
