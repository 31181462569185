import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useToast } from 'primevue/usetoast'
import type { ToastMessageOptions } from 'primevue/toast'

export const useToastStore = defineStore('toastStore', () => {
	const toast = useToast()
	const maxToasts = 2
	const toastQueue = ref<ToastMessageOptions[]>([])

	const add = (opt: ToastMessageOptions) => {
		if (toastQueue.value.length >= maxToasts) {
			const oldToast = toastQueue.value.shift()
			if (oldToast) {
				toast.remove(oldToast)
			}
		}

		toastQueue.value.push(opt)
		toast.add(opt)
	}

	return { add }
})
